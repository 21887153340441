import * as React from 'react';

import DataService from '../../../services/dataService';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { graphql, navigate, Navigate } from 'gatsby';

import Layout from '../Layout';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Moment from 'react-moment';
import { siteMetadata } from '../../../../gatsby-config';

import { useDispatch } from 'react-redux';
import { setUpdatingData } from '../../../redux/admin/adminSlice';
import { data } from 'autoprefixer';

const Posts = ({ data, pageContext, location }) => {

    const dispatch = useDispatch();

    const [posts, setPosts] = React.useState([]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [searchDelayOngoing, setSearchDelayOngoing] = React.useState(false);
    const [searchResults, setSearchResults] = React.useState([]);
    const [dateCategorizedPosts, setDateCategorizedPosts] = React.useState({});
    const [singularPostType, setSingularPostType] = React.useState('');

    React.useEffect(() => {

        dispatch(setUpdatingData({ updatingData: true }));

        if (location.state) {
            if (typeof location.state.postType !== 'undefined') {
                DataService.getAllRecords(siteMetadata.datoCmsModelIds.Post, data.datoCmsWebsite.id, location.state.postType)
                .then( posts => {
                    console.log(posts)
                    setPosts(posts)
                    setSingularPostType(posts[0].post_type_singular);
                    categorizePostsByDate(posts);
                    dispatch(setUpdatingData({ updatingData: false }));
                })
            }
        } else {
            navigate('/admin/posts');
            dispatch(setUpdatingData({ updatingData: false }));
        }
    },[])

    React.useEffect(() => {
        console.log(singularPostType);
    }, [singularPostType])

    React.useEffect(() => {
        
        setTimeout(() => setSearchDelayOngoing(false), 500);
    }, [searchDelayOngoing]);

    React.useEffect(() => {

        if (searchTerm.length == 0) setSearchResults([]);
    }, [searchTerm]);

    const searchPosts = async (query) => {

        if (!searchDelayOngoing) {
            DataService.filter(query)
                .then( results => {
                    setSearchResults(results); 
                }
            )

            return;
        }

        setSearchDelayOngoing(true);
    }

    const categorizePostsByDate = (postsSuff) => {
        
        let today = [];
        let lastWeek = [];
        let lastMonth = [];
        let older = [];

        let searched = [];
        postsSuff.forEach( post => {

            if (Date.parse(post.published) > (Date.now() - 86400000)) {
                today.push(post)
                return;
            }
            if (Date.parse(post.published) > (Date.now() - 604800000) ) {
                lastWeek.push(post);
                return;
            }
            if (Date.parse(post.published) > (Date.now() - 2419200000)) {
                lastMonth.push(post);
                return;
            }
            
            older.push(post);

        });

        setDateCategorizedPosts({
            'Today': today,
            'Last Week': lastWeek,
            'Last Month': lastMonth,
            'Posts older than a month': older
        });
    }

    const editNewPost = () => {

        dispatch(setUpdatingData({ updatingData: true }));

        const updatedData = {
            item_type: { type: 'item_type', id: siteMetadata.datoCmsModelIds.Post.toString() },
            title: '',
            site: data.datoCmsWebsite.id.split('-')[1],
            posttype: location.state.postType,
            post_type_singular: singularPostType,
            published: new Date().toISOString()
        }

        DataService.create(updatedData)
            .then( newPost => {
                navigate('/admin/posts/edit', { 
                    state: {
                        postId: newPost.id
                    }
                });
            });

        dispatch(setUpdatingData({ updatingData: false }));
    }

    return (
        <Layout>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', position: 'relative',  }}>
                {/* <h1 style={{ fontFamily: 'monospace' }}>
                    Posts
                </h1> */}
                {/* <TextField 
                    id="outlined-basic" 
                    label="Search posts..." 
                    variant="outlined" 
                    placeholder='Search posts...' 
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                        if (e.target.value.length) searchPosts(e.target.value);
                    }}
                    autoComplete="off"
                    style={{
                        position: 'absolute',
                        right: 0
                    }}
                /> */}
                {
                    searchResults.length ? (
                        <Box sx={{ bgcolor: 'background.paper' }} style={{ 
                            position: 'absolute',
                            top: 53,
                            right: -1,
                            width: 222,
                            borderStyle: 'solid',
                            borderColor: 'grey',
                            borderWidth: 1,
                            borderRadius: 2
                        }}>
                            <List style={{ width: '100%', padding: 0 }}>
                                {searchResults.map( (searchResult, index) => (
                                    <div key={index}>
                                        <ListItem disablePadding style={{ width: '100%' }}>
                                            <ListItemButton style={{ width: '100%' }} onClick={() => {
                                                navigate('/admin/posts/edit', {
                                                    state: {
                                                        postId: searchResult.id,
                                                        imgurl: searchResult.image.url,
                                                        imageId: searchResult.image.id,
                                                        siteName: pageContext.sitename
                                                    }
                                                })
                                            }}>
                                                <ListItemText>{searchResult.title}</ListItemText>
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider  component="li" />
                                    </div>
                    ))}
                            </List>
                        </Box>
                    ) : (
                        <div style={{ display: 'none' }} />
                    )
                }
            </div>



            <div>
                {
                    <Tooltip title="New Post" placement="left" arrow>
                        <IconButton style={{ 
                            alignSelf: 'right', 
                            backgroundColor: 'green', 
                            margin: 12, 
                            color: "white", 
                            verticalAlign: 'center',
                            position: 'absolute',
                            right: 20,
                            bottom: 20,
                            borderRadius: 300,
                            height: 65,
                            width: 65,
                            backgroundColor: '#45425a'
                        }} 
                            variant="contained" 
                            aria-label="Update post" 
                            onClick={() => editNewPost()}
                        >
                            <AddIcon style={{ color: "whitesmoke", padding: 5, borderRadius: 40, margin: 10 }} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                }
                {Object.keys(dateCategorizedPosts).map( (key, index) => {

                    if (!dateCategorizedPosts[key].length) return;

                    return (
                        <div key={index} className="mb-5">
                            <h1 className="text-2xl font-bold" style={{ fontFamily: 'monospace', color: '#45425a' }}>{key}</h1>
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                                {dateCategorizedPosts[key].map( (post, index) => (
                                    <Card key={index} sx={{}}
                                        style={{
                                            margin: 5
                                        }}
                                        onClick={() => {
                                            navigate('/admin/posts/edit', {
                                                state: {
                                                    postId: post.id,
                                                    imgurl: post.imgurl
                                                }
                                            })   
                                        }}
                                        >
                                        <CardActionArea>
                                            <div 
                                                style={{

                                                }}
                                            >

                                            </div>
                                            <CardContent className="flex flex-col justify-evenly relative p-4">
                                                <Typography key={1} varient="h5" component="div" className="text-md font-bold text-center" style={{ color: '#45425a' }}>
                                                    {post.title}
                                                </Typography>
                                                {post.draft ? (
                                                    <Typography key={3} sx={{ fontSize: 14 }} color="text.secondary" className="text-lg text-center opacity-40 bottom" style={{ color: '#45425a' }} gutterBottom>
                                                        {post.draft ? 'Draft' : ''}
                                                    </Typography>
                                                )
                                                :
                                                (
                                                    <Typography key={2} sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                                        published on <Moment format="D MMM YYYY" >{post.published}</Moment>
                                                    </Typography>
                                                )}
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    )
                })}
            </div>
        </Layout>
    )
}

export const query = graphql`
    query getSiteInSelectPostsQuery($sitename: String) {
        datoCmsWebsite(name: {eq: $sitename}) {
            id
        }
    }
`

export default Posts;
